<template>
  <div class="auctionShow_page">
    <div class="center_plate flex">
      <div class="cp_navi" @mouseleave="categoryShow = !!0">
        <div v-for="(item, index) in categoryList" :key="index" class="cp_nivi_border flex">
          <div class="cp_navi_items cp_background" @mouseover="mouseIn(item)">
            <span>{{ item.name }}</span>
            <!-- <i class="el-icon-arrow-right"></i> -->
          </div>
          <div v-for="(items, indexs) in item.child" :key="indexs" class="cp_navi_item">
            <div @click="navigateTo(`/categoryShow/categoryShow/${items.id}/${items.name}`)">
              {{items.name}}{{items.child.length !=0 ? '('  + items.child.length + ')' : ''}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="top_title">
        <span>展示</span>
      </div>
      <div class="sec_l_content">
        <goodsList :list="list"></goodsList>
        <el-empty v-if="list.length === 0" description="暂无商品"></el-empty>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="amount"
        :page-size="10"
        :current-page="page"
        @current-change="currentChange"
        @prev-click="prevPage"
        @next-click="nextPage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import goodsList from "@/components/goodsList";
export default {
  components: { goodsList },
  data() {
    return {
      categoryList: [], //分类
      categoryShow: !!0,
      keyword: "",
      list: [],
      amount: 0,
      current: 0,
      page: 1,
      type: null,
    };
  },
  watch: {},
  methods: {
    mouseIn(e) {
      this.categoryShow = !0;
      this.categoryChildlist = e.child;
    },
    getDatas() {
      // 获取分类
      this.$http.CategoryList().then((res) => {
        this.categoryList = res.data.goods_type.map((v) => {
          v.show = !!0;
          return v;
        });
        this.categoryList.push(
          {
            name: '商品检索',
            child: [
              {
                name: '最新发布',
                child: [],
                id: '1.1'
              },
              {
                name: '精品推荐',
                child: [],
                id: '1.2'
              },
              {
                name: '多次出价',
                child: [],
                id: '1.3'
              },
              {
                name: '一口价',
                child: [],
                id: '1.4'
              },
              {
                name: '保证金',
                child: [],
                id: '1.5'
              },
              {
                name: '50元以下',
                child: [],
                id: '1.6'
              },
              {
                name: '50-100元',
                child: [],
                id: '1.7'
              },
              {
                name: '100-300元',
                child: [],
                id: '1.8'
              },
              {
                name: '300元以上',
                child: [],
                id: '1.9'
              },
            ]
          }
        )
      });
      this.$http.IndexGoods("page=1&sx=1&shop_province=1").then((res) => {
        this.boutiqueGoodsList = res.data.goods;
      });
      this.$http.IndexGoods("page=1&sx=1&shop_province=4").then((res) => {
        this.newGoodsList = res.data.goods;
      });
    },
    currentChange(e) {
      this.page = e;
      this.getData();
    },
    prevPage(e) {
      this.page = e;
      this.getData();
    },
    nextPage(e) {
      this.page = e;
      this.getData();
    },
    getData() {
      this.$http
        .IndexGoods(`page=${this.page}&sx=1&shop_province=${this.type}`)
        .then((res) => {
          this.list = res.data.goods;
          this.amount = res.data.count
        });
    },
    category() {},
  },
  mounted() {
    this.type = this.$route.params.type;
    // console.log(this.type, "---type");
    this.getData();
    this.getDatas();
  },
};
</script>
<style lang="scss" scoped>
.auctionShow_page {
  min-height: 600px;
}
.container {
  margin-top: 50px;
  width: 100%;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  .top_title {
    line-height: 50px;
    height: 50px;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 800;
    border-bottom: 1px solid #c0c0c0;
  }
}
.pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #c70707; //修改后的背景图颜色
    color: #fff;
  }
}
.center_plate {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0 0 0;
  .cp_navi {
    width: 100%;
    margin: auto;
    position: relative;
    color: rgb(20, 20, 20);
    border-bottom: 1px solid #cfcfcf;
    .cp_nivi_border {
      border-top: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
    }
    .cp_background {
      background: #d5d5d5;
    }
    .cp_navi_item {
      align-items: center;
      justify-content: space-between;
      height: 42px;
      width: 100px;
      line-height: 42px;
      cursor: pointer;
      text-align: center;
    }
    .cp_navi_items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      width: 135px;
      line-height: 42px;
      padding: 0 30px;
      cursor: pointer;
    }
    .cp_navi_item:hover {
      background-color: #ac3030;
    }
    .cp_navi_content {
      position: absolute;
      left: 234px;
      top: 0;
      z-index: 5;
      min-width: 370px;
      height: 460px;
      background-color: #fff;
      box-shadow: 0 0 5px #99a9bf;
      color: #000;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 18px;
      font-weight: 800;
      .cp_navi_con_item {
        width: 150px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px solid #c0c0c0;
        border-radius: 5px;
         cursor: pointer;
      }
      .cp_navi_con_item:hover {
        background-color: #e2e2e2;
      }
    }
  }
  .box_carousel {
    width: calc(100% - 234px);
    height: 460px;

    .imgs {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
</style>